import React from "react";

import "../styles/Intro.css";
import Typist from "react-typist";
import "react-typist/dist/Typist.css";
import EmailRoundedIcon from "@material-ui/icons/EmailRounded";
import FadeInSection from "./FadeInSection";
import DataNetwork from "./DataNetwork";

class Intro extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
      visible: true,
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    return (
      <div id="intro">
        <DataNetwork></DataNetwork>
        <Typist avgTypingDelay={100}>
          <span className="intro-title">
            {"hello, I'm "}
            <span className="intro-name">{"Jatin"}</span>
            {"."}
          </span>
        </Typist>
        <FadeInSection>
          <div className="intro-subtitle">I code stuff, sometimes!</div>
          <div className="intro-desc">
            I am a Software Engineer based in Toronto (CA), currently leveraging
            my full-stack development skills to build data platform products.
            With extensive experience in creating scalable, high-impact software
            solutions, I have significantly improved platform efficiencies and
            user experiences. As I expand into data and analytics, I bring a
            strong analytical skill set and a commitment to using data to drive
            technological and business innovation, while leveraging my
            full-stack development expertise to build comprehensive, scalable
            solutions.
          </div>
          <a href="mailto:surijatin16@gmail.com" className="intro-contact">
            <EmailRoundedIcon></EmailRoundedIcon>
            {" Connect with me!"}
          </a>
        </FadeInSection>
      </div>
    );
  }
}

export default Intro;
