import React from "react";
import { ReactP5Wrapper } from "react-p5-wrapper";
import "../styles/DataNetwork.css";

const numNodes = 50; // Number of nodes in the network

const Sketch = (p5) => {
  let nodes = [];

  p5.setup = () => {
    p5.createCanvas(800, 400);
    p5.frameRate(30); // Lower frame rate for smoother animation
    for (let i = 0; i < numNodes; i++) {
      nodes.push(new Node(p5.random(p5.width), p5.random(p5.height), p5));
    }
  };

  p5.draw = () => {
    p5.background("#0a192f");
    nodes.forEach((node) => {
      node.move();
      node.display();
    });
    connectNodes(p5, nodes);
  };

  function connectNodes(p5, nodes) {
    nodes.forEach((node, index) => {
      for (let j = index + 1; j < nodes.length; j++) {
        let distance = p5.dist(node.x, node.y, nodes[j].x, nodes[j].y);
        if (distance < 100) {
          p5.stroke(100, 255, 218);
          p5.strokeWeight(1.5);
          p5.line(node.x, node.y, nodes[j].x, nodes[j].y);
        }
      }
    });
  }

  class Node {
    constructor(x, y, p5) {
      this.x = x;
      this.y = y;
      this.p5 = p5;
      this.speedX = p5.random(-1, 1);
      this.speedY = p5.random(-1, 1);
    }

    move() {
      this.x += this.speedX;
      this.y += this.speedY;

      // Reflect off edges
      if (this.x <= 0 || this.x >= this.p5.width) this.speedX *= -1;
      if (this.y <= 0 || this.y >= this.p5.height) this.speedY *= -1;
    }

    display() {
      this.p5.fill(255);
      this.p5.stroke(255);
      this.p5.ellipse(this.x, this.y, 10, 10);
    }
  }
};

const DataNetwork = () => (
  <div id="data-network">
    <ReactP5Wrapper sketch={Sketch} />
  </div>
);

export default DataNetwork;
