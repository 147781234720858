import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import FadeInSection from "./FadeInSection";

const isHorizontal = window.innerWidth < 600;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  if (isHorizontal) {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`full-width-tabpanel-${index}`}
        aria-labelledby={`full-width-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  } else {
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`vertical-tabpanel`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  if (isHorizontal) {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  } else {
    return {
      id: `vertical-tab-${index}`,
    };
  }
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: "theme.palette.background.paper",
    display: "flex",
    height: 300,
  },
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
  },
}));

const JobList = () => {
  const classes = useStyles();
  const [value, setValue] = React.useState(0);

  const experienceItems = {
    PhonePe: {
      jobTitle: "Senior Software Engineer, Web @",
      duration: "JUL 2022 - JUN 2023",
      desc: [
        "Led Web UI design and implementation of PhonePe Portal by defining user roles and entitlements; enhancing UX and reducing platform access wait times by 200%",
        "Revamped platform UI to cut down query turnaround time, boosting call center efficiency by 20% with intuitive design, fewer clicks and consistent layout",
        "Designed and implemented frontend for Interactive Voice Response, reducing average call duration by 30 seconds and streamlining the assistance process for call center employees",
      ],
    },
    "Crejo.Fun": {
      jobTitle: "Software Engineer @",
      duration: "OCT 2021 - JUN 2022",
      desc: [
        "Redesigned Web UI for product purchase by minimizing clicks for customers resulting in 50% reduction in user drop-offs on booking page",
        "Performed A/B testing on web pages to optimize navigation and interactions, resulting in a 6% increase in user conversion rates",
        "Analyzed platform web pages for design inconsistencies and implemented a reusable component library, ensuring brand uniformity and saving over 20 man-hours weekly",
      ],
    },
    HSBC: {
      jobTitle: "Software Engineer @",
      duration: "JUL 2019 - OCT 2021",
      desc: [
        "Transformed designs and wireframes into high-quality code with ReactJS and Redux; engineered a common front-end library, saving over 200 man-hours of development",
        "Led a team, prioritized tasks based on skills and reviewed code pull requests, to ensure delivery timelines and product quality standards are achieved",
        "Addressed security issues and vulnerabilities reported on 180k+ lines of code and enforced strict standards of coding among 4 different teams",
        "Increased unit test coverage by 90% utilizing Enzyme and Jest frameworks, ensuring robust code quality and reliability",
      ],
    },
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div className={classes.root}>
      <Tabs
        orientation={!isHorizontal ? "vertical" : null}
        variant={isHorizontal ? "fullWidth" : "scrollable"}
        value={value}
        onChange={handleChange}
        className={classes.tabs}
      >
        {Object.keys(experienceItems).map((key, i) => (
          <Tab
            label={
              isHorizontal
                ? `Lvl-${Object.keys(experienceItems).length - 1 - i}.`
                : key
            }
            {...a11yProps(i)}
          />
        ))}
      </Tabs>
      {Object.keys(experienceItems).map((key, i) => (
        <TabPanel value={value} index={i}>
          <span className="joblist-job-title">
            {experienceItems[key]["jobTitle"] + " "}
          </span>
          <span className="joblist-job-company">{key}</span>
          <div className="joblist-duration">
            {experienceItems[key]["duration"]}
          </div>
          <ul className="job-description">
            {experienceItems[key]["desc"].map(function (descItem, i) {
              return (
                <FadeInSection delay={`${i + 1}00ms`}>
                  <li key={i}>{descItem}</li>
                </FadeInSection>
              );
            })}
          </ul>
        </TabPanel>
      ))}
    </div>
  );
};

export default JobList;
