import React from "react";
import "../styles/Projects.css";
import FolderOpenRoundedIcon from "@material-ui/icons/FolderOpenRounded";
import FadeInSection from "./FadeInSection";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import Carousel from "react-bootstrap/Carousel";
import ExternalLinks from "./ExternalLinks";
import { EffectCreative, Pagination, Autoplay } from "swiper/modules";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";

class Projects extends React.Component {
  constructor() {
    super();
    this.state = {
      expanded: true,
      activeKey: "1",
    };
    this.handleSelect = this.handleSelect.bind(this);
  }
  handleSelect(eventKey) {
    this.setState({
      activeKey: eventKey,
    });
  }
  render() {
    const spotlightProjects = {
      Streamify: {
        title: "Streamify",
        desc: "Interactive React-based web application that visualizes key metrics and performance indicators for a fictional music streaming service",
        techStack: "ReactJS, TypeScript, TailwindCSS, shadcn/ui, Redux",
        open: "https://streamify-five-rho.vercel.app/",
        link: "https://github.com/surijatin/streamify",
        image: "/assets/Group 11.png",
        mobImage: "/assets/mob-streamify.png",
      },
      LaunchLens: {
        title: "Launch Lens",
        desc: "An AI tool powered by GPT-4 that evaluates and ranks startup ideas based on specific bussiness parameters.",
        techStack: "NextJS, TypeScript, TailwindCSS, Python, GPT-4",
        open: "https://launchlens-ecorizz.vercel.app/",
        image: "/assets/Group 6.png",
        mobImage: "/assets/mob-launchlens.png",
      },
      aldo: {
        title: "Tinder for Shoes: Aldo Swipe",
        desc: "A 24-hour GenAI Hackathon project that generates new shoe designs based on Customer's likes and dislikes.",
        techStack: "ReactJS, Python, MySQL, OpenAI, Mid-Journey, Adolo",
        link: "https://github.com/surijatin/aldo-web",
        open: "https://previewer.adalo.com/3c8e0024-e4f8-4a85-8967-f3cbac5c25f2",
        image: "/assets/Group 8.png",
        mobImage: "/assets/mob-aldo.png",
      },
      sqlExecutor: {
        title: "Query Mate",
        desc: "A tool for easy SQL query execution on local MySQL databases, ideal for development, testing, and learning, with a user-friendly interface.",
        techStack: "ReactJS, Javasrcipt, AntD, Axios, MySQL",
        link: "https://github.com/surijatin/sql-executor",
        image: "/assets/Group 7.png",
        mobImage: "/assets/mob-query.png",
      },
    };
    const projects = {
      chatMTL: {
        desc: "An interactive chatbot that engages users with dynamically sourced content from MTL Blog using the advanced Llama conversational model. It enhances user interaction by providing curated, up-to-date news.",
        techStack: "Python, Llama, Tkinter, Socket Programming, BeautifulSoup",
        link: "https://github.com/surijatin/chatMTL",
      },
      "WildFire Risk Assessment": {
        desc: "Advanced wildfire prediction and risk management tool leveraging Canada's open-source data for improved environmental safety, resource allocation and strategic decision-making.",
        techStack:
          "Python, Pandas, scikit-learn, Matplotlib, Folium, Machine Learning",
        link: "https://github.com/surijatin/wildfire-risk-assessment",
      },
      "Academic Darwanism": {
        desc: "A project aimed towards leveraging Social Media Analytics techniques to analyze university academic data and explore the correlation between students' academic performance and their social networks within the school.",
        techStack: "Python, Pandas, NetworkX, Matplotlib, Machine Learning",
        link: "https://github.com/surijatin/Academic-Darwanism-Social_Network_Analytics",
      },
      "COVID-19 Second Dosage": {
        desc: "A practice project to demonstrate the use of ReactJS and Vanilla CSS to create a simple web application that helps users track their second COVID-19 vaccine dosage.",
        techStack: "ReactJS, JavaScript, Vanilla CSS, Github Pages",
        link: "https://github.com/surijatin/covid-second-dose",
        open: "https://surijatin.github.io/covid-second-dose/",
      },
      SuperChat: {
        desc: "A simple Youtube Live Chat Mockup using ReactJS and MaterialUI for frontend and Firebase for real-time communication.",
        techStack: "ReactJS, Firebase, MaterialUI, Github Pages",
        link: "https://github.com/surijatin/superchat/",
        open: "https://surijatin.github.io/superchat//",
      },
    };

    return (
      <div id="projects">
        <div className="section-header ">
          <span className="section-title">&lt;Projects/&gt;</span>
        </div>
        <Swiper
          effect={"creative"}
          creativeEffect={{
            prev: {
              shadow: true,
              translate: [0, 0, -400],
            },
            next: {
              translate: ["100%", 0, 0],
            },
          }}
          modules={[Pagination, Autoplay, EffectCreative]}
          centeredSlides={true}
          slidesPerView={1}
          pagination={{
            clickable: true,
            renderBullet: function (index, className) {
              return (
                '<span class="' +
                className +
                '" style="background-color: var(--green-bright);"></span>'
              );
            },
          }}
          loop
          autoplay={{
            delay: 1500,
            disableOnInteraction: false,
          }}
        >
          {Object.keys(spotlightProjects).map((key, i) => (
            <SwiperSlide key={i} style={{ height: "100%" }}>
              <div className="carousel-card" style={{ height: "100%" }}>
                <img
                  className="card-img-top"
                  src={spotlightProjects[key]["mobImage"]}
                  alt={key}
                />
                <h3 className="card-title">
                  {spotlightProjects[key]["title"]}
                </h3>
                <p className="card-desc" style={{ marginBottom: "0px" }}>
                  {spotlightProjects[key]["desc"]}
                </p>
                <p className="card-tech">
                  {spotlightProjects[key]["techStack"]}
                </p>
                <ExternalLinks
                  githubLink={spotlightProjects[key]["link"]}
                  openLink={spotlightProjects[key]["open"]}
                ></ExternalLinks>
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        <Carousel>
          {Object.keys(spotlightProjects).map((key, i) => (
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={spotlightProjects[key]["image"]}
                alt={key}
              />
              <div className="caption-bg">
                <Carousel.Caption>
                  <h3>{spotlightProjects[key]["title"]}</h3>
                  <p>{spotlightProjects[key]["desc"]}</p>
                  <p className="techStack">
                    {spotlightProjects[key]["techStack"]}
                  </p>
                  <ExternalLinks
                    githubLink={spotlightProjects[key]["link"]}
                    openLink={spotlightProjects[key]["open"]}
                  ></ExternalLinks>
                </Carousel.Caption>
              </div>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="project-container">
          <ul className="projects-grid">
            {Object.keys(projects).map((key, i) => (
              <FadeInSection delay={`${i + 1}00ms`}>
                <li className="projects-card">
                  <div className="card-header">
                    <div className="folder-icon">
                      <FolderOpenRoundedIcon
                        style={{ fontSize: 35 }}
                      ></FolderOpenRoundedIcon>
                    </div>
                    <ExternalLinks
                      githubLink={projects[key]["link"]}
                      openLink={projects[key]["open"]}
                    ></ExternalLinks>
                  </div>

                  <div className="card-title">{key}</div>
                  <div className="card-desc">{projects[key]["desc"]}</div>
                  <div className="card-tech">{projects[key]["techStack"]}</div>
                </li>
              </FadeInSection>
            ))}
          </ul>
        </div>
      </div>
    );
  }
}

export default Projects;
