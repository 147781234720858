import React from "react";
import JobList from "./JobList";
import "../styles/Experience.css";
import FadeInSection from "./FadeInSection";

class Experience extends React.Component {
  render() {
    return (
      <div className="container">
        <div id="experience">
          <FadeInSection>
            <div
              className="section-header"
              style={{
                marginLeft: window.innerWidth <= 600 ? "24px" : "0",
                paddingBottom: window.innerWidth <= 600 && "24px",
              }}
            >
              <span className="section-title">&lt;Experience/&gt;</span>
            </div>
            <JobList />
          </FadeInSection>
        </div>
      </div>
    );
  }
}

export default Experience;
